import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Anatomy`}</h2>
    <p>{`The blankslate component is made up of several elements that work together to inform the user about a feature and how to proceed forward. Below are the different elements of the component and how to modify them.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/user-attachments/assets/d7bfdc56-ef85-4264-8ac2-ea6a41e73942",
        "alt": "Blankslate anatomy"
      }}></img></p>
    <h3>{`Graphic`}</h3>
    <p>{`The graphic can bring delight, preview an interface element, or represent the goal of the feature. Graphics should be placed intentionally and with thought about the intention of the content. Graphics also differ in meaning and appeal to the user, which is why the Blankslate component has multiple variations. These variations are outlined later on this page.`}</p>
    <h3>{`Primary Text`}</h3>
    <p>{`Use primary text to explain the purpose of the empty state, help users feel comfortable to engage with the content, or begin a feature flow. Primary text should sound welcoming, human, and convey the intention of the feature.`}</p>
    <h3>{`Secondary text`}</h3>
    <p>{`This optional text is used to inform the user about the feature in more detail. Secondary text should be brief and non-redundant if possible. From the text, users should be able to understand the general purpose of the feature and how it may help them accomplish a goal.`}</p>
    <h3>{`Primary action`}</h3>
    <p>{`Blankslates can and are encouraged to use one primary action. This button should lead to a feature or component creation flow. Button copy should be kept brief and descriptive. If a button requires further specification, consider adding an Octicon.`}</p>
    <h3>{`Secondary action`}</h3>
    <p>{`Secondary actions are optional and are represented by a text link located below the primary action button. A secondary action is used to direct a user to additional content about the feature. This might look like `}<inlineCode parentName="p">{`Learn more about X`}</inlineCode>{` or "`}<inlineCode parentName="p">{`Check out the guide on X`}</inlineCode>{`.`}</p>
    <h3>{`Border`}</h3>
    <p>{`The border is invisible by default, but can be added to help define the structure of the Blankslate component when needed. This can be particularly helpful in page layouts where the Blankslate is not the only content on the screen. For implementation, check out the `}<a parentName="p" {...{
        "href": "https://primer.style/css/components/blankslate#add-border"
      }}>{`Primer CSS Blankslate component`}</a>{`.`}</p>
    <h2>{`Variations`}</h2>
    <p>{`Empty states have multiple variations that can be used in different contexts.`}</p>
    <h3>{`Octicons`}</h3>
    <p><a parentName="p" {...{
        "href": "https://primer.style/foundations/icons"
      }}>{`Octicons`}</a>{` can be used to represent the feature where the Blankslate is found.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/user-attachments/assets/9fd63765-a113-4c2d-ba30-339369797e06",
        "alt": "Octicon Icon Blankslate"
      }}></img></p>
    <h3>{`Code block`}</h3>
    <p>{`Blankslates that use a list of steps or offer instructions in the format of code to get started with a feature can insert a `}<inlineCode parentName="p">{`code`}</inlineCode>{` block. This is the case for getting started with packages in GitHub:`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/primer/design/assets/813754/b44601fa-7698-48be-a884-037c724d334c",
        "alt": "Code block Blankslate"
      }}></img></p>
    <h2>{`Content and copy`}</h2>
    <p>{`Empty states should explain features `}<em parentName="p">{`in addition`}</em>{` to conveying intention. In the example below, the primary text is used to provide a welcome invitation to creating a positive community while the secondary text supports this intent by informing the user on how to do this by providing a code of conduct.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/primer/design/assets/813754/51aba49d-346d-496e-9631-1702888667e0",
        "alt": "code of conduct illustration Blankslate"
      }}></img></p>
    <h2>{`First time user experiences`}</h2>
    <p>{`For first time user experiences, use illustration Blankslates to playfully engage the user and introduce the Octocat as a symbol of GitHub. Primary text should welcome the user to the platform and feature. Secondary text should seek to educate the user, but at a simpler, less-technical level.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/primer/design/assets/813754/3caf0160-d369-40b6-86dc-d2eac967a21d",
        "alt": "first time user Blankslate"
      }}></img></p>
    <h2>{`In a narrow container`}</h2>
    <p>{`When a Blankslate is rendered in a narrow area, the spacing and font sizes are reduced so that it's visually proportional to the narrow area it's being rendered in. This is implemented using container queries, so this will happen on any viewport size.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/primer/design/assets/2313998/95cd99e1-5d8a-4b2d-849e-430d17f61d02",
        "alt": "Blankslate with smaller spacing and text"
      }}></img></p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Blankslate" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      